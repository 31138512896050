@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-base: "Poppins";

// Import necessary Bootstrap functions, variables, and utilities first
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Define your custom translation utility inside $utilities map
$utilities: map-merge(
  (
    "translate-bottom-right": (
      property: transform,
      class: translate-bottom-right,
      values: (
        null: translate(-100%, -100%)
      )
    ),
    "translate-bottom": (
      property: transform,
      class: translate-bottom,
      values: (
        null: translate(0,-100%)
      )
    ),
  ),
  $utilities
);

$utilities: map-merge(
  (
    "w-33": (
      property: width,
      class: w-33,
      values: (
        null: 33.33%
      )
    ),
    "w-66": (
      property: width,
      class: w-66,
      values: (
        null: 66.66%
      )
    ),
  ),
  $utilities
);

// Define your custom colors
$gold: #ffd700;
$silver: #b1b1b1;

// Add the custom colors to the map
$theme-colors: map-merge(
  (
    "gold": $gold,
    "silver": $silver
  ),
  $theme-colors
);

// Include the utilities with the custom one
@import "~bootstrap/scss/bootstrap";
@import "./avatar";
@import "./profile";
@import "./events";

.App {
  background: url('../assets/retrocamo.webp');
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

#eventAdminToggle::after {
  display: none!important;
}

.nav-link-c {
    display: block;
    padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}nav-link-padding-x);
    @include font-size(var(--#{$prefix}nav-link-font-size));
    font-weight: var(--#{$prefix}nav-link-font-weight);
    color: var(--#{$prefix}nav-link-color);
    text-decoration: if($link-decoration == none, null, none);
    border: 0;
    @include transition($nav-link-transition);
  
    &:hover,
    &:focus {
      color: var(--#{$prefix}nav-link-hover-color);
      text-decoration: if($link-hover-decoration == underline, none, null);
    }
  
    &:focus-visible {
      outline: 0;
      box-shadow: $nav-link-focus-box-shadow;
    }
  
    // Disabled state lightens text
    &.disabled,
    &:disabled {
      color: var(--#{$prefix}nav-link-disabled-color);
      pointer-events: none;
      cursor: default;
    }
  }

  .pac-container{
    z-index: 9999;
  }

  
.loader {
  position: fixed;
  translate: -50% -50%;
  top:50%;
  left: 50%;
    width: 150px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 16px solid lightblue;
    border-right-color: rgb(0, 141, 31);
    animation: l2 1s infinite linear;
  }
@keyframes l2 {to{transform: rotate(1turn)}}

.loader-container {
    width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
  background: url('../assets/retrocamo.webp');
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}
.loader-container-hidden {
  opacity: 0;
  visibility: hidden;
}

.card-clear {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  // --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  // --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.bi-pencil-square{
  cursor: pointer;
}
