.events-list{
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.events-list:hover {
  cursor: pointer;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.player-attendance-card {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 25px;
  width: fit-content;
  min-width: fit-content;
}
.player-attendance-card-small {
  border: 1px solid rgba(0,0,0,.125);
  width: 100%;
  padding: 4px;
}