.avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    min-height: 50px;
    min-width: 50px;
  }
  .md-avatar .avatar {
    width: 75px;
    height: 75px;
    min-height: 75px;
    min-width: 75px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    border: 3px solid white;
    
  }
  .large-avatar .avatar {
    width: 100px;
    height: 100px;
    min-height: 100px;
    min-width: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    border: 3px solid white;
    
  }
  .avatar img {
    width: 100%;
    height: 100%;
  }
  .nav-avatar .avatar, .comment-avatar .avatar {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    vertical-align: middle;
  }
  .nav-avatar .avatar-text {
    vertical-align: middle;
  }
  a.nav-avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid $gray-300;
    vertical-align: middle;
    text-align: center;
    background-color: $gray-300;
  }