.profile-image {
    @include img-fluid();
    display: inline-block;
    width: 250px;
    height: 250px;
    min-height: 250px;
    min-width: 250px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid white;
}
.profile-image-sm {
    @include img-fluid();
    display: inline-block;
    width: 150px;
    height: 150px;
    min-height: 150px;
    min-width: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid white;
}
.profile-image-changer {
    display: inline-block;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: show;
}
.profile-image-changer-sm {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: show;
}